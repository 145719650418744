<template>

    <v-content>
        <v-expansion-panels v-model="panel" multiple hover>
            <v-expansion-panel>
                <v-expansion-panel-header>Filter</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-container fluid>
                        <v-row col="12">
                            <v-row align="start" justify="end">
                                <v-col class="d-flex" cols="12" sm="3">
                                    <v-menu :close-on-content-click="false" v-model="menu1"
                                            transition="scale-transition" offset-y :nudge-right="40" max-width="290px"
                                            min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field required @input="$v.fromDate.$touch()"
                                                          @blur="$v.fromDate.$touch()" :error-messages="fromDateErrors"
                                                          slot="activator" v-on="on" label="From Date"
                                                          v-model="fromDate" prepend-icon="event"
                                                          readonly></v-text-field>
                                        </template>
                                        <v-date-picker v-model="fromDate" @click="menu1 = false" no-title scrollable
                                                       actions>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col class="d-flex" cols="12" sm="3">
                                    <v-menu :close-on-content-click="false" v-model="menu2"
                                            transition="scale-transition" offset-y :nudge-right="40" max-width="290px"
                                            min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field required @input="$v.toDate.$touch()"
                                                          @blur="$v.toDate.$touch()" :error-messages="toDateErrors"
                                                          slot="activator" v-on="on" label="To Date" v-model="toDate"
                                                          prepend-icon="event" readonly></v-text-field>
                                        </template>
                                        <v-date-picker v-model="toDate" no-title scrollable actions>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col class="d-flex" cols="12" sm="4">


                                    <v-select :items="items" v-model="select"
                                              :error-messages="selectErrors"
                                              required
                                              @change="$v.select.$touch()"
                                              @blur="$v.select.$touch()"
                                              label="Status"></v-select>
                                </v-col>

                                <v-col class="d-flex" cols="12" sm="2">
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="mx-2" fab dark small color="primary"
                                                   @click="exportButton = true">
                                                <v-icon dark v-on="on" @click="submit">send</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Send</span>
                                    </v-tooltip>

                                    <v-btn v-if="exportButton" color="primary" dark @click="exportDate" class="ml-2">
                                        Export
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-row>
                        <v-divider class="hr-divider" vertical></v-divider>
                        <!--                        <v-col class="float-right" cols="12" sm="4">-->
                        <!--                            <v-text-field label="Seartch input" v-model="serverParams.search" @keyup="searchInput"></v-text-field>-->
                        <!--                        </v-col>-->

                    </v-container>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <vue-good-table mode="remote"
                        @on-page-change="onPageChange"
                        @on-sort-change="onSortChange"
                        @on-column-filter="onColumnFilter"
                        @on-per-page-change="onPerPageChange"
                        :totalRows="totalRecords"
                        :isLoading.sync="isLoading"
                        :pagination-options="{ enabled: true, }"
                        :rows="rows"
                        :columns="columns"
                        theme="black-rhino">
            <!--            added action column in vue good table-->
            <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'">
            <v-tooltip left>
                 <template v-slot:activator="{ on }">
                     <router-link :to="'/user/list/'+1" class="view"><i class="material-icons eye" v-on="on">remove_red_eye</i></router-link>
                 </template>
                <span>View User</span>

            </v-tooltip>
        </span>
                <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
            </template>
        </vue-good-table>
    </v-content>
</template>

<script>
    // https://github.com/dwqs/js2excel export to excel
    import {required} from 'vuelidate/lib/validators'
    import {json2excel} from 'js2excel'
    import immigrationData from '../../assets/json/immigration'
    import axios from 'axios'

    export default {
        name: 'List',
        watch: {
            dialog(val) {
                val || this.close()
            },
        },
        components: {},
        validations: {
            fromDate: {required},
            toDate: {required},
            select: {required},
        },
        data() {
            return {
                exportButton: false,
                panel: [0],
                fromDate: '',
                menu1: false,
                modal1: false,
                toDate: '',
                menu2: false,
                modal2: false,
                isLoading: false,
                select: null,
                items: ['Arrival', 'Departure'],
                apiImmigrationData: immigrationData,
                columns: [
                    {
                        label: 'Country of Residence',
                        field: 'Country of Residence'
                    },
                    {
                        label: 'Passport Number',
                        field: 'Passport Number'
                    },
                    {
                        label: 'Date of Arrival',
                        field: 'Date of Arrival'
                    },
                    {
                        label: 'Date of Departure',
                        field: 'Date of Departure'
                    },
                    {
                        label: 'Gender',
                        field: 'Gender'
                    },

                    {
                        label: 'Date of Birth',
                        field: 'Date of Birth'
                    },

                    {
                        label: 'Port of Entry',
                        field: 'Port of Entry'
                    },

                    {
                        label: 'Contact Details',
                        field: 'Contact Details'
                    },

                    {
                        label: 'Visa Issue Date',
                        field: 'Visa Issue Date'
                    },
                    {
                        label: 'Actions',
                        field: 'actions',
                        sortable: false,
                    }
                ],
                rows: [],
                totalRecords: 0,
                serverParams: {
                    search: '',
                    columnFilters: {},
                    sort: [{
                        field: 'branchID',
                        type: 'asc',
                    }],
                    page: 1,
                    perPage: 10
                },
            };
        },
        computed: {
            fromDateErrors() {
                const errors = []
                if (!this.$v.fromDate.$dirty) return errors
                !this.$v.fromDate.required && errors.push('From Date should be required')
                return errors
            },
            toDateErrors() {
                const errors = []
                if (!this.$v.toDate.$dirty) return errors
                !this.$v.toDate.required && errors.push('To Date should be required')
                return errors
            },
            selectErrors() {
                const errors = []
                if (!this.$v.select.$dirty) return errors
                !this.$v.select.required && errors.push('Item is required')
                return errors
            },
        },

        mounted() {
            this.loadItems()
        },
        methods: {
            exportDate() {
                try {
                    json2excel({
                        data: this.apiImmigrationData,
                        name: 'ImmigrationData',
                    });
                } catch (e) {
                    console.error('export error');
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            searchInput() {
                this.loadItems();
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            // load items is what brings back the rows from server
            async loadItems() {
                // eslint-disable-next-line no-console
                if (this.totalRecords < this.serverParams.perPage) {
                    this.serverParams.perPage = 10
                    this.serverParams.page = 1
                }
                let param = {
                    "PageSize": this.serverParams.perPage,
                    "PageNo": this.serverParams.page,
                    "OrderType": this.serverParams.sort[0].type,
                    "OrderBy": this.serverParams.sort[0].field,
                    "Name": this.serverParams.search
                }

                // eslint-disable-next-line no-console
                console.log(this.serverParams.sort) // eslint-disable-next-line no-console
                console.log(this.serverParams.sort[0].type)

                let list = await axios.post('Branch/BranchList', param)
                this.rows = list.data.data
                this.totalRecords = list.data.totalCount

                return
            },
            submit() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.submitStatus = 'ERROR'
                } else {
                    this.rows = this.apiImmigrationData
                }
            },
        }
    };
</script>

<style lang="scss" scoped>

    .v-item-group {
        background: #d2e3ff73
    }

    .v-expansion-panel-header {
        background: #d2e3ff73
    }

    .eye {
        padding: 0.5em;
    }

    .view {
        text-decoration: none;
    }

    .hr-divider {
        width: 100%;
        max-width: 100%;
        border-width: 1px;
    }
</style>
